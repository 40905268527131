import React, { useState, useEffect } from "react";
import useFormContext from "../../hooks/useFormContext";
import FilePdfUploader from "../FilePdfUploader";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { PhoneInput } from "../ui/phone-input";
import { useTranslation } from "react-i18next";
import { SampleDatePicker } from "../ui/Sample-date-picker";
export const curriculum_list = [
  "منهج سوداني",
  "British Curriculum",
];
export const arabicClasses = [
  "الاول حضانة",
  "الثاني حضانة",
  "الاول روضة",
  "الثاني روضة",
  "الثالث روضة",
  "الاول اساس",
  "الثاني اساس",
  "الثالث اساس",
  "الرابع اساس",
  "الخامس اساس",
  "السادس اساس",
  "الاول متوسط",
  "الثاني متوسط",
  "الثالث متوسط",
  "الاول ثانوي",
  "الثاني ثانوي",
  "الثالث ثانوي",
];

export const engLishClasses = [
  "Baby Class",
  "Nursery",
  "FS 0",
  "FS 1",
  "FS 2",
  "Year 1",
  "Year 2",
  "Year 3",
  "Year 4",
  "Year 5",
  "Year 6",
  "Year 7",
  "Year 8",
  "Year 9",
  "Year 10",
  "Year 11",
  "Year 12",
];
const StdInfo = () => {
  const { t } = useTranslation();
  const { setData, data } = useFormContext();
  const [name, setName] = useState("");
  const [birthDate, setBirthDate] = useState(new Date());
  const [curriculum, setCurriculum] = useState("");
  const [className, setClassName] = useState("");
  const [gender, setGender] = useState("");
  const [religion, setReligion] = useState("");
  const [img, setImg] = useState(null);
  const [birthCert, setBirthCert] = useState(null);
  const [passport, setPassport] = useState(null);
  const [docOne, setDocOne] = useState(null);
  const [docTwo, setDocTwo] = useState(null);
  const [lastAttendedSchool, setLastAttendedSchool] = useState("");
  const [lastAttendedSchoolPhone, setLastAttendedSchoolPhone] = useState("");
  const [lastAttendedSchoolEmail, setLastAttendedSchoolEmail] = useState("");
  const [visa, setVisa] = useState("");
  const [classes, setClasses] = useState([]);


  /*const handleCustomElementChange = (value) => {
    setLastAttendedSchoolPhone(value)
  };*/

  useEffect(() => {
    const givenYear = moment(new Date(birthDate));
    const currentFullYear = new Date().getFullYear();
    const currentYear = moment(new Date(`30/Sep/${currentFullYear}`));

    if (!isNaN(givenYear)) {
      const differ = currentYear.diff(givenYear);
      const diffDuration = moment.duration(differ);
   
      if (diffDuration.years() < 1) {
        setClassName("");
      } else {
      if (curriculum ==="منهج سوداني"){
        setClasses(arabicClasses)
        setClassName(arabicClasses[diffDuration.years()-1]);
      } else if (curriculum ==="British Curriculum") {
        setClasses(engLishClasses)
        setClassName(engLishClasses[diffDuration.years()-1]);
      }
    }
    }
  }, [birthDate, curriculum]);

  const AddToStudentList = () => {
    if (name === "") {
      toast.error(t("req_name"));
      return;
    }
    if (birthDate === "") {
      toast.error(t("req_date_of_birth"));
      return;
    }
    if (curriculum === "") {
      toast.error(t("req_curriculum"));
      return;
    }
    if (className === "") {
      toast.error(t("req_class"));
      return;
    }
    if (gender === "") {
      toast.error(t("req_gender"));
      return;
    }
    if (religion === "") {
      toast.error(t("req_religion"));
      return;
    }
    /*if (img === null) {
      toast.error("Photo is required.");
      return;
    }
    if (birthCert === null) {
      toast.error("Birth certificate is required.");
      return;
    }
    if (passport === null) {
      toast.error("Passport is required.");
      return;
    }
    if (visa === null) {
      toast.error("Visa is required.");
      return;
    }
    if (docOne === null && className !== "Toddlers") {
      toast.error("Last report card is required.");
      return;
    }
    if (docTwo === null && className !== "Toddlers" && className !== "Nursery") {
      toast.error("second last report card is required.");
      return;
    }
    if (
      lastAttendedSchool === "" &&
      className !== "Toddlers" &&
      className !== "Nursery"
    ) {
      toast.error(t("req_last_attend_school"));
      return;
    }
    if (
      lastAttendedSchoolPhone === "" &&
      className !== "Toddlers" &&
      className !== "Nursery"
    ) {
      toast.error(t("req_last_attend_phone"));
      return;
    }
    if (
      lastAttendedSchoolEmail === "" &&
      className !== "Toddlers" &&
      className !== "Nursery"
    ) {
      toast.error(t("req_last_attend_email"));
      return;
    }
    if (
      !isValidEmail(lastAttendedSchoolEmail) &&
      className !== "Toddlers" &&
      className !== "Nursery"
    ) {
      toast.error(t("req_last_attend_email_valid"));
      return;
    }*/
    const std = {
      name,
      birthDate: moment(birthDate).format(),
      curriculum,
      className,
      gender,
      religion,
      img,
      birthCrt: birthCert,
      passportCrt: passport,
      docOneCrt: docOne,
      docTwoCrt: docTwo,
      lastAttendedSchool: lastAttendedSchool,
      lastAttendedSchoolPhone: lastAttendedSchoolPhone,
      lastAttendedSchoolEmail: lastAttendedSchoolEmail,
      visaCrt: visa,
    };

    setData((prevData) => ({
      ...prevData,
      s_info: [...data.s_info, { ...std }],
    }));

    setName("");
    setBirthDate(new Date());
    setCurriculum("");
    setClassName("");
    setGender("");
    setReligion("");
    setBirthCert(null);
    setImg(null);
    setPassport(null);
    setDocOne(null);
    setDocTwo(null);
    setLastAttendedSchool("");
    setLastAttendedSchoolPhone("");
    setLastAttendedSchoolEmail("");
    setVisa(null);
  };

  const content = (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col w-full">
        <label htmlFor="std_firstName">
          {t("1st_name")} <span className="text-red-600">*</span>
        </label>
        <input
          type="text"
          className="form-textbox"
          id="std_firstName"
          name="std_firstName"
          value={name}
          pattern="([A-Z])[\w+.]{1,}"
          onChange={(e) => setName(e.target.value)}
        />
      </div>

      <div className="flex flex-col w-full">
        <label htmlFor="std_dateofbirth">
          {t("date_of_birth")} <span className="text-red-600">*</span>
        </label>
        {/* <input
          type="date"
          className="form-textbox"
          id="std_dateofbirth"
          name="std_dateofbirth"
          value={birthDate}
          onChange={(e) => setBirthDate(e.target.value)}
          onSelect={(e) => setBirthDate(e.target.value)}
        /> */}
        <SampleDatePicker
          id="std_dateofbirth"
          name="std_dateofbirth"
          date={birthDate}
          setDate={setBirthDate}
        />
      </div>
      <div className="flex flex-col w-full">
        <label htmlFor="std_curriculum">
          {t("curriculum")} <span className="text-red-600">*</span>
        </label>
        <select
          id="std_curriculum "
          className="form-textbox"
          name="std_curriculum"
          value={curriculum }
          onChange={(e) => {
            setCurriculum(e.target.value)
            setClasses(e.target.value === "منهج سوداني" ? arabicClasses : e.target.value === "British Curriculum" ? engLishClasses : [])
          }}
        >
          <option value="">{t("select")}</option>
          {curriculum_list.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
      <div className="flex flex-col w-full">
        <label htmlFor="std_class">
          {t("class")} <span className="text-red-600">*</span>
        </label>
        <select
          id="std_class"
          className="form-textbox"
          name="std_class"
          value={className}
          onChange={(e) => setClassName(e.target.value)}
        >
          <option value="">{t("select")}</option>
          {classes.map(x => (<option key={x} value={x}>{x}</option>))}
        </select>
        {/* <input
          type="text"
          className="form-textbox"
          id="std_class"
          name="std_class"
          value={className}
          disabled
          onChange={(e) => setClassName(e.target.value)}
        /> */}
      </div>

      <div className="flex flex-col w-full">
        <label htmlFor="std_religion">
          {t("religion")} <span className="text-red-600">*</span>
        </label>
        <select
          id="std_religion"
          className="form-textbox"
          name="std_religion"
          value={religion}
          onChange={(e) => setReligion(e.target.value)}
        >
          <option value="">{t("select")}</option>
          <option value={t("muslim")}>{t("muslim")}</option>
          <option value={t("christian")}>{t("christian")}</option>
          <option value={t("other")}>{t("other")}</option>
        </select>
      </div>

      <div className="flex flex-col w-full">
        <label htmlFor="std_gender">
          {t("gender")} <span className="text-red-600">*</span>
        </label>
        <select
          id="std_gender"
          className="form-textbox"
          name="std_gender"
          value={gender}
          onChange={(e) => setGender(e.target.value)}
        >
          <option value="">{t("select")}</option>
          <option value={t("male")}>{t("male")}</option>
          <option value={t("female")}>{t("female")}</option>
        </select>
      </div>

      <div className="flex flex-col w-full">
        <label htmlFor="std_lastAttendedSchool">
          {t("last_attended_school")} {className !== "Toddlers"}
        </label>
        <input
          type="text"
          className="form-textbox"
          id="std_lastAttendedSchool"
          name="std_lastAttendedSchool"
          value={lastAttendedSchool}
          required={false}
          pattern="([A-Z])[\w+.]{1,}"
          onChange={(e) => setLastAttendedSchool(e.target.value)}
        />
      </div>

      <div className="flex flex-col w-full">
        <label htmlFor="std_lastAttendedSchoolPhone">
          {t("last_attended_school_phone")} {className !== "Toddlers"}
        </label>
        <PhoneInput
          defaultCountry="EG"
          id="std_lastAttendedSchoolPhone"
          name="std_lastAttendedSchoolPhone"
          value={lastAttendedSchoolPhone}
          required={false}
          onChange={(e) => setLastAttendedSchoolPhone(e)}
        />
      </div>

      <div className="flex flex-col w-full">
        <label htmlFor="std_lastAttendedSchoolEmail">
          {t("last_attended_school_email")} {className !== "Toddlers"}
        </label>
        <input
          type="email"
          className="form-textbox"
          id="std_lastAttendedSchoolEmail"
          name="std_lastAttendedSchoolEmail"
          value={lastAttendedSchoolEmail}
          required={false}
          pattern="/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/"
          onChange={(e) => setLastAttendedSchoolEmail(e.target.value)}
        />
      </div>

      <label className="mt-5">{t("uploads")}</label>
      <hr />

      <div className="flex justify-center items-center gap-5 flex-wrap">
        <FilePdfUploader
          setFileElement={setImg}
          FileElement={img}
          acceptFiles="image/jpg, image/png, image/jpeg"
          defaultUrl=""
          label={t("photo")}
          required={false}
        />
        <FilePdfUploader
          setFileElement={setBirthCert}
          FileElement={birthCert}
          acceptFiles="*"
          defaultUrl=""
          label={t("birth_certificate")}
          required={false}
        />
        <FilePdfUploader
          setFileElement={setPassport}
          FileElement={passport}
          acceptFiles="*"
          defaultUrl=""
          label={t("passport")}
          required={false}
        />
        <FilePdfUploader
          setFileElement={setDocOne}
          FileElement={docOne}
          acceptFiles="*"
          defaultUrl=""
          label={t("last_report_card")}
          required={false} //required={className !== "Toddlers"}
        />
        <FilePdfUploader
          setFileElement={setDocTwo}
          FileElement={docTwo}
          acceptFiles="*"
          defaultUrl=""
          label={t("second_last_report_card")}
          required={false} //required={className !== "Toddlers" && className !== "Nursery"}
        />
        <FilePdfUploader
        setFileElement={setVisa}
        FileElement={visa}
        acceptFiles="image/jpg, image/png, image/jpeg"
        defaultUrl=""
        label={t("visa")}
        required={false}
      />
      </div>
      <button
        type="button"
        onClick={AddToStudentList}
        className="bg-transparent mt-5 hover:bg-Teal text-blue-dark font-semibold hover:text-white py-2 px-4 border border-blue hover:border-transparent rounded cursor-pointer"
      >
        {t("add")}
      </button>
    </div>
  );

  return content;
};

export default StdInfo;
