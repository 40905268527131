import React from "react";
import { BsFacebook, BsInstagram, BsTwitter, BsWhatsapp } from "react-icons/bs";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <motion.div
      initial={{ height: 0 }}
      whileInView={{ height: "auto" }}
      transition={{ duration: 1 }}
      className="bg-Teal p-10"
    >
      <div className="grid md:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 grid-cols-1 place-items-start gap-8 text-white">
        <div>
          <div className="font-bold mb-6">{t("contact")}</div>
          <div className="text-sm mb-4">info@sis.smartschool.sd</div>
          <div className="mb-4">
            <div className="text-sm mb-1">
            {t("school_address")}            
            </div>
            <div className="text-sm">01150621648</div>
            <div className="text-sm">01148745068</div>
            <div className="text-sm">01503244588</div>
          </div>
        </div>

        <div>
          <div className="font-bold mb-6">{t("follow_us")}</div>
          <div className="flex gap-4 mt-4">
            <a
              href="https://www.facebook.com/Sunriseinternationalschool16"
              target={"_blank"}
              rel="noreferrer"
              className="hover:scale-110 text-xl"
            >
              <BsFacebook />
            </a>
            <a
              href="https://www.instagram.com/sunrise_school16"
              target={"_blank"}
              rel="noreferrer"
              className="hover:scale-110 text-xl"
            >
              <BsInstagram />
            </a>
            <a
              href="https://x.com/sunriseschoolsu"
              target={"_blank"}
              rel="noreferrer"
              className="hover:scale-110 text-xl"
            >
              <BsTwitter />
            </a>
            <a
              href="https://wa.me/201150621648"
              target={"_blank"}
              rel="noreferrer"
              className="hover:scale-110 text-xl"
            >
              <BsWhatsapp />
            </a>
          </div>
        </div>
        <div>
          <div className="font mb-6">
            <a href="http://www.keeti.sd" target={"_blank"} rel="noreferrer">
              {t("powered_by")}
            </a>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Footer;
