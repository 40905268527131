import React from "react";

import { About, Journey, Courses,Contact,Categories } from "./index";
import AnimatedPage from "./AnimatedPage";
import Hero from "./Hero-s";

import { useTranslation } from "react-i18next";

const HomePage = () => {
  
  const { t } = useTranslation();
  return (
    <AnimatedPage>
      <Hero />
      {/* <Home /> */}
      <About />
      {<Courses />}
      { <Journey /> }
      { <Contact /> }
    </AnimatedPage>
  );
};

export default HomePage;
